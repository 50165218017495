<template lang="html">
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span
          class="text-muted d-block text-center text-sm-left d-sm-inline-block"
          >Copyright © 2022 Waltily. All rights reserved.</span
        >
      </div>
    </footer>
  </section>
</template>

<script lang="js">
export default {
  name: 'app-footer'
}
</script>
